/* You can add global styles to this file, and also import other style files */
@import "ngx-toastr/toastr";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Geologica";
    font-style: normal;
    font-weight: 200 400 600 800 900;
    font-display: swap;
    src: url("./fonts/Geologica.ttf") format("truetype");
  }

  @font-face {
    font-family: "camprrr";
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("./fonts/Comfortaa_camprrr_Bold.ttf") format("truetype");
  }

  body {
    @apply text-body;
    position: relative;
    min-height: 100vh;
    height: 100%;
    scroll-behavior: smooth;
    background-color: #003333;
    color: white;
  }

  @layer components {
    .input {
      @apply border border-dark-green rounded-2xl px-4 py-2;
    }

    .input:focus-visible {
      border-color: #9f6 !important;
      box-shadow: 0 0 0 2px rgba(177, 245, 109, 0.06);
      border-inline-end-width: 1px;
      outline: 0;
    }

    button:disabled {
      opacity: 0.7;
    }
  }

  h1 {
    @apply text-heading-1;
  }

  h2 {
    @apply text-heading-2;
  }

  h3 {
    @apply text-heading-3;
  }

  h4 {
    @apply text-heading-4;
  }

  h5 {
    @apply text-heading-5;
  }

  h6 {
    @apply text-heading-6;
  }
}

/* Modal */
.nsm-dialog {
  top: 50%;
  transform: translateY(-70%);
  max-width: 704px !important;
  @media screen and (max-width: 768px) {
    transform: translateY(-50%);
  }
}

.nsm-content {
  margin-top: 0;
  padding: 50px 40px;
  border-radius: 50px;
  color: #003333;
}

.nsm-dialog-btn-close {
  top: 30px;
  right: 44px;
}

/* Form */
form.ng-submitted input.ng-invalid {
  border-color: #ff5b5b !important;
}

form.ng-submitted input.ng-invalid:focus,
form.ng-submitted input.ng-invalid:focus-visible {
  box-shadow: 0 0 0 2px rgba(255, 38, 5, 0.06);
  border-inline-end-width: 1px;
  outline: 0;
}

/* Inputs */
::placeholder {
  font-style: italic;
  font-weight: 200;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

#toast-container {
  pointer-events: none;
}

#toast-container > * {
  pointer-events: auto;
}

/* .owl-item {
  max-width: 202px;
} */
